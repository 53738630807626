import React from "react";

import WrapperLink from "./ui/WrapperLink";

import { useTranslation } from "react-i18next";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
// import { faMobileAlt, faMapPin } from "@fortawesome/free-solid-svg-icons";

import "../css/Contact.css";

const ContactItem = props => {
  return (
    <div className={`w-33-l w-100 flex flex-column tc justify-center items-center ${props.className ?? ""}`}>
      <WrapperLink href={props.href} className="f4 link dim bleu-canard no-underline">
        {/* <FontAwesomeIcon className="mb3 black" icon={props.icon} size="3x" /> */}
        <img src={`/icons/${props.icon}`} alt="icons" />
        <div className="link-title">{props.title}</div>
        <div className="link-separator"></div>
        <div className="link-info">{props.subtitle}</div>
      </WrapperLink>
    </div>
  );
};

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mw9 center section-contact">
        <h3 className="title">{t("contact.header")}</h3>
        <div className="text">{t("contact.text")}</div>
        <div className="flex flex-column flex-row-l items-start">
          <ContactItem
            icon="ctc-email.png"
            title={t("contact.email")}
            subtitle="request@ziegler-sr.com"
            href={`mailto:request@ziegler-sr.com`}
          />
          <ContactItem
            icon="ctc-phone.png"
            title={t("contact.tel")}
            subtitle="+33 1 48 16 76 54"
            href="tel:+33 1 48 16 76 54"
            className="mt5 mt0-l"
          />
          <ContactItem
            icon="ctc-address.png"
            title={t("contact.addr")}
            subtitle="32 rue des Voyelles - CDG Airport France"
            className="mt5 mt0-l"
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
