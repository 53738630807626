import React from "react";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../css/common.css";
import "../css/pages/404.css";

import Header from "../components/Header";
import Footer from "../components/Footer";
import SectionTextPage from "../components/ui/SectionTextPage";
import Seo from "../components/SeoComponent";
import Contact from "../components/Contact";

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo title={t("p404.metadata.name")} description={t("p404.metadata.description")} />
      <Header />

      <SectionTextPage>
        <div className="flex flex-column items-center">
          <img src="/images/404.png" alt="404" />
          <img src="/images/sad-package.png" alt="404" />
        </div>
      </SectionTextPage>

      <SectionTextPage mode="dark">
        <p className="p404-text tc">
          <span dangerouslySetInnerHTML={{ __html: t("p404.text") }}></span>&nbsp;
          <Link to="/">{t("p404.homepage")}</Link>.
        </p>
      </SectionTextPage>

      <SectionTextPage>
        <Contact />
      </SectionTextPage>

      <Footer />
    </>
  );
};

export default Page404;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
